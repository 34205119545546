import React from 'react';
import CardItem from './CardItem';

function CardInfo(props) {
    let arr = [
        { name: 'Example heading', img: 'https://picsum.photos/seed/picsum/100/80?random=1' },
        { name: 'Example heading', img: 'https://picsum.photos/seed/picsum/100/80?random=2' },
        { name: 'Example heading', img: 'https://picsum.photos/seed/picsum/100/80?random=3' },
    ]
    return (
        <>
            {arr.map((item, index) =>
                <CardItem key={index} name={item.name} img={item.img} />
            )}
        </>
    );
}

export default CardInfo;