import React from 'react';
import { Card, Placeholder, Col } from 'react-bootstrap'

function CardItem(props) {
    let name = props.name;
    let img = props.img;

    return (
        <>
            <Col xs={12} lg={4} >
                <Card >
                    <Card.Img variant="top" src={img} />
                    <Card.Body>
                        <Placeholder as={Card.Title} animation="glow">
                            {name}
                        </Placeholder>
                        <Placeholder as={Card.Text} animation="glow">
                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                            <Placeholder xs={6} /> <Placeholder xs={8} />
                        </Placeholder>
                        <Placeholder.Button variant="primary" xs={6} />
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
}

export default CardItem;