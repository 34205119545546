import { Container, Row } from 'react-bootstrap';
import About from './components/layouts/About';
import Banner from './components/layouts/Banner';
import CardInfo from './components/layouts/CardInfo';
import Footer from './components/layouts/Footer';
import Header from './components/layouts/Header'
import Meta from './components/layouts/Meta';


function App() {
  return (
    <>
      <Meta />
      <Header />
      <Container>
        <Row className="row-banner">
          <Banner className="row-banner" />
        </Row>
        <Row className="row-info">
          <CardInfo />
        </Row>
        <About />
      </Container>
      <Footer />
    </>
  );
}

export default App;
