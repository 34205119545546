import React from 'react';
import MetaTags from 'react-meta-tags';

function Meta(props) {
    return (
        <MetaTags>
            <title>ZaloApp Mini App</title>
            <meta name="description" content="ZaloApp Mini App" />
            <meta property="og:title" content="ZaloApp Mini App" />
        </MetaTags>
    );
}

export default Meta;