import React from 'react';
import { Container, Row } from 'react-bootstrap';

function Footer(props) {
    let year = new Date().getFullYear()

    return (
        <>
            <Container>
                <hr />
                <Row className="row-footer text-center">
                    <p className="credit" >Copyright &copy; {year} by
                        <a className="link_copyright" link href="https://krago.xyz/"> Krago Software Outsoucing</a>
                    </p>
                </Row>
            </Container>
        </>
    );
}

export default Footer;